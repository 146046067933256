import Loading from "vue-loading-overlay";
import PaginationNav from "../../../components/PaginationNav.vue";
import PaginationMobile from "../../../components/PaginationMobile.vue";
import axios from "axios";
import moment from "moment";

import * as config from "@/config.json";

export default {
  name: "EventsHistory",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
  },
  data: () => {
    return {
      isLoading: false,
      token: null,
      selectedOptionAplication: null,
      optionsAplications: [],
      selectedOptionSite: null,
      search: null,
      optionsSites: [],
      selectedOptionPOS: null,
      optionsPOS: [],
      pagination: {},
      paginationRange: 11,
      events: [],
      fieldsEvents: [{
          key: "createdAt",
          label: "Data"
        },
        {
          key: "id",
          label: "ID"
        },
        {
          key: "type",
          label: "Tipo de Evento"
        },
        {
          key: "reason",
          label: "Motivo"
        },
        {
          key: "notes",
          label: "Observações"
        },
        {
          key: "document",
          label: "Documento"
        },
        {
          key: "productSku",
          label: "Barras"
        },
        {
          key: "productDescription",
          label: "Descrição"
        },
        {
          key: "customerName",
          label: "Cliente"
        },
        {
          key: "siteFantasy",
          label: "Loja"
        },
        {
          key: "checked",
          label: "Verificado"
        }

      ],
      currentPage: 1,
      perPage: 10,
      selectedOption: null,
      options: [],
      selectedOptionApp: null,
      optionsApps: [],
      totalRecords: 0,
      selectedOptionCompany: null,
      optionsCompany: [],
      isBusy: false,
      selectedSite: null,
      posBySite: [],
      startDate: null,
      endDate: null,
      selectedOptionDate: 2,
      optionsDate: [{
          value: null,
          text: "Sem Intervalo de Datas",
        },
        {
          value: 1,
          text: "Ontem",
        },
        {
          value: 2,
          text: "Hoje",
        },
        {
          value: 3,
          text: "Últimos 7 dias",
        },
        {
          value: 4,
          text: "Últimos 15 dias",
        },
        {
          value: 5,
          text: "Últimos 30 dias",
        },
        {
          value: 6,
          text: "Com Intervalo de Datas",
        },
      ],
      selectedEventsVerificationRadio: 0,
      optionsSelectVerificationEventsRadio: [{
          item: 0,
          name: "Todos"
        },
        {
          item: 1,
          name: "Não Verificado"
        },
        {
          item: 2,
          name: "Verificado"
        },
      ],
    };
  },
  methods: {
    async navigate(page) {
      await this.searchEvents(page);
    },

    clearPOS() {
      this.selectedOptionPOS = null;
      this.searchEvents(this.currentPage);
    },

    async clearCompany() {
      this.selectedOptionCompany = null;
      this.searchEvents(this.currentPage);
    },

    async clearSites() {
      this.selectedOptionSite = null;
      this.searchEvents(this.currentPage);
    },

    async clearApp() {
      this.selectedOptionApp = null;
      this.searchEvents(this.currentPage);
    },

    async clearSearch() {
      this.search = null;
      this.searchEvents(this.currentPage);
    },

    focus(id) {
      if (id === 'filterInput') {
        this.search = ''
      }

      document.getElementById(id).focus();
    },

    async buildParamsGetPos(siteId) {
      let params = new Object();

      params.siteId = siteId

      return params
    },

    async getPosBySiteId(site) {

      const url = `${config.default.urlBase}/pos`;
      const params = await this.buildParamsGetPos(site);

      try {
        const response = await axios({
          url,
          method: 'GET',
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {

          this.optionsPOS = [];
          this.optionsPOS = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `POS - ${item.number}`
            };
          }));

          if (this.optionsPOS.length == 1) {
            if (!this.selectedOptionPOS) {
              this.selectedOptionPOS = this.optionsPOS[0].value;
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsPOS = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os POS! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getApps() {
      const url = `${config.default.urlBase}/apps/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        this.optionsApps = [];
        if (response.status == 200) {
          this.optionsApps = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsApps.length == 1) {
            if (!this.selectedOptionApp) {
              this.selectedOptionApp = this.optionsApps[0].value;
            }
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.optionsApps = [];
        this.isLoading = false;


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        this.optionsCompany = [];
        this.optionsSites = [];
        this.optionsPOS = [];
        this.optionsApps = [];
        this.options = [];

        if (response.status == 200) {

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value;
            }

            await this.getSitesByCompany(this.selectedOptionCompany);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.optionsCompany = [];
        this.isLoading = false;


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    buildDataEventUpdate(event) {
      return {
        companyId: this.selectedOptionCompany,
        checked: event.checked
      }
    },

    async confirmCheckEvent(event) {
      const res = await this.$swal({
        title: "Confirmação",
        text: event.checked == 0 ? `Desmarcar o check do evento ${event.id}?` : `Marcar o evento ${event.id} como checado?`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#303030",
        cancelButtonColor: "#fe5c3a",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (res.isConfirmed) {
        await this.checkEvent(event);
      } else {
        this.searchEvents(this.currentPage);
      }
    },

    async checkEvent(event) {
      try {
        this.isLoading = true;

        const eventId = event.id;
        const url = `${config.default.urlBase}/events/${eventId}/update`;
        const data = this.buildDataEventUpdate(event);

        const response = await axios({
          url,
          method: "put",
          data,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            "O evento foi atualizado com sucesso!",
            "Aviso",
            "success"
          )

          this.searchEvents(this.currentPage);
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao atualizar o evento! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        this.optionsSites = [];
        this.selectedOptionSite = null;

        if (response.status == 200) {
          this.optionsSites = await Promise.all(
            response.data.map((item) => {
              return {
                value: item.id,
                text: `${item.id} - ${item.name}`,
              };
            })
          );

          if (this.optionsSites.length == 1) {
            this.selectedOptionSite = this.optionsSites[0].value;

            await this.getPosBySiteId(this.optionsSites[0].value);
          }
        }

        await this.searchEvents(this.currentPage);
      } catch (error) {
        console.log({
          error
        });

        this.optionsSites = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    },

    clearFilter() {
      this.selectedOption = null;

      this.searchEvents(this.currentPage);
    },

    buildSearchEvents(companyId, siteId, posId, appId, filterOption, selectedOptionDate, selectedEventsVerificationRadio, page) {
      let data = new Object();

      if (companyId) {
        data.companyId = companyId
      }

      if (siteId) {
        data.siteId = siteId
      }

      if (posId) {
        data.posId = posId
      }

      if (appId) {
        data.appId = appId
      }

      if (filterOption) {
        data.typeId = this.selectedOption
      }

      if (filterOption == 1) {
        if (this.startDate && this.endDate) {
          data.startDate = moment(this.startDate).format("YYYY-MM-DD 00:00:00");
          data.endDate = moment(this.endDate).format("YYYY-MM-DD 23:59:59");
        }
      }

      if (selectedOptionDate == 1) {
        data.startDate = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00")
        data.endDate = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59")
      }

      if (selectedOptionDate == 2) {
        data.startDate = moment().format("YYYY-MM-DD 00:00:00")
        data.endDate = moment().format("YYYY-MM-DD 23:59:59")
      }

      if (selectedOptionDate == 3) {
        data.startDate = moment().subtract(6, 'days').format("YYYY-MM-DD 00:00:00")
        data.endDate = moment().format("YYYY-MM-DD 23:59:59")
      }

      if (selectedOptionDate == 4) {
        data.startDate = moment().subtract(14, 'days').format("YYYY-MM-DD 00:00:00")
        data.endDate = moment().format("YYYY-MM-DD 23:59:59")
      }

      if (selectedOptionDate == 5) {
        data.startDate = moment().subtract(29, 'days').format("YYYY-MM-DD 00:00:00")
        data.endDate = moment().format("YYYY-MM-DD 23:59:59")
      }

      if (selectedOptionDate == 6) {
        if (this.startDate != null && this.endDate != null) {
          data.startDate = moment(this.startDate).format("YYYY-MM-DD 00:00:00")
          data.endDate = moment(this.endDate).format("YYYY-MM-DD 23:59:59")
        }
      }

      if (selectedEventsVerificationRadio == 1) {
        data.isChecked = false
      }

      if (selectedEventsVerificationRadio == 2) {
        data.isChecked = true
      }

      data.page = page
      data.pageSize = this.perPage

      return data
    },

    async getEventTypes() {
      try {
        const url = `${config.default.urlBase}/event/types`;
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {

          this.options = [];
          this.options = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} -  ${item.description}`
            };
          }));
        }
      } catch (error) {
        console.log({
          error
        })

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }

        if (error.response.status == 500) {
          await this.$alert(
            `Ocorreu um erro ao buscar os tipos de eventos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },


    clearSelectedOptions() {
      this.selectedOptionPOS = null
      this.selectedOptionApp = null
      this.selectedOption = null
    },


    async searchEvents(page) {
      if (!this.selectedOptionCompany) {
        this.events = []
        this.pagination = {}
        this.totalRecords = 0;

        return
      }

      try {
        this.isBusy = true;

        const url = `${config.default.urlBase}/events/search`;

        const data = this.buildSearchEvents(this.selectedOptionCompany, this.selectedOptionSite, this.selectedOptionPOS, this.selectedOptionApp, this.selectedOption, this.selectedOptionDate, this.selectedEventsVerificationRadio, page);

        const response = await axios({
          url,
          method: "post",
          data,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.isBusy = false;

          this.events = response.data.items

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.totalRecords = response.data.records;
        }
      } catch (error) {
        console.log({
          error,
        });

        this.isBusy = false;
        this.events = [];

        this.pagination = {}

        this.totalRecords = 0;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar o histórico de eventos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },


    redirect(ref) {
      this.$router.push(ref);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
        showIcon: true,
      });

      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    await this.getCompanies();
    await this.getApps();
    // await this.getSitesByCompany(this.selectedOptionCompany);
    await this.getEventTypes();
    this.isLoading = false;
  },
};